type ButtonProps = {
  children: any;
  disabled?: boolean;
};

const Button = ({ children, disabled }: ButtonProps) => {
  return (
    <button
      disabled={disabled || false}
      className="rounded-md w-full py-2 px-4 md:py-3 md:px-6
      bg-indigo-600 text-white border-solid border-2 
      focus:outline-none 
      hover:bg-white hover:text-indigo-600 hover:border-indigo-600 
      disabled:text-white disabled:border-gray-400 disabled:bg-indigo-600 disabled:opacity-75 disabled:cursor-not-allowed
      transition duration-200"
    >
      {children}
    </button>
  );
};

export default Button;
