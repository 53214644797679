import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Button from "../Button";

type ContactFormData = {
  name: string;
  email: string;
  projectDetails: string;
  phoneNumber?: string;
  preferredContact: string;
};

enum PreferredContact {
  Email = "Email",
  Phone = "Phone",
}

const Contact = () => {
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ContactFormData>({
    mode: "onChange",
  });

  const formEl = useRef(null);
  const recaptchaEl = useRef<ReCAPTCHA>(null);

  const { t } = useTranslation();

  const handleFormSubmit = async (contactFormData: ContactFormData) => {
    if (
      (contactFormData.preferredContact as PreferredContact) ===
        PreferredContact.Phone &&
      !contactFormData.phoneNumber
    ) {
      setError("preferredContact", {
        message: t("contact-phone-number-required"),
        type: "required",
      });
      return;
    }

    setError("preferredContact", {});

    const recaptchaToken = await recaptchaEl.current.executeAsync();

    recaptchaEl.current.reset();

    setIsSendingEmail(true);

    try {
      const response = await fetch("/api/send-email", {
        method: "POST",
        body: JSON.stringify({
          ...contactFormData,
          recaptchaToken,
        }),
        headers: {
          "Content-type": "application/json",
        },
      });

      if (response.status === 422) {
        throw new Error("ReCAPTCHA validation failed.");
      }

      if (!response.ok) {
        throw new Error("contact-failed-email");
      }

      toast.success(t("contact-success-email"));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSendingEmail(false);
    }
  };

  const onError = (): void => {
    formEl.current.classList.add("animate-shaking");
  };

  const onAnimationEnd = (): void => {
    formEl.current.classList.remove("animate-shaking");
  };

  const emailError = errors.email?.type === "required";
  const nameError = errors.name?.type === "required";
  const projectDetailsError = errors.projectDetails?.type === "required";
  const preferredContactError = errors.preferredContact?.type === "required";

  return (
    <div className="bg-primary">
      <div className="text-center py-14">
        <div className="flex justify-center text-3xl md:text-4xl">
          <h2 className="text-indigo-600 pr-2">{t("contact")}</h2>
          <h2 className="font-extralight">{t("us")}</h2>
        </div>
      </div>
      <div
        id="contact"
        className="flex flex-col justify-center items-center mx-auto pb-20 lg:w-5/6 xl:w-4/6 lg:justify-between lg:flex-row"
      >
        <div className="text-2xl">
          <div className="">
            <div className="text-5xl md:text-6xl pr-4 font-extrabold">
              {t("lets")}
            </div>
            <div className="text-5xl md:text-6xl pt-4 font-extrabold">
              {t("connect")}
            </div>
          </div>
          <div>
            <Image
              src={"/images/mail.svg"}
              width={350}
              height={350}
              alt={"mail"}
            ></Image>
          </div>
        </div>
        {/* Contact form */}
        <div
          ref={formEl}
          onAnimationEnd={onAnimationEnd}
          className={`bg-white rounded-3xl shadow-xl p-16 w-5/6 lg:w-7/12 mx-auto lg:pt-10 lg:-mx-0`}
        >
          <form
            className="space-y-6"
            onSubmit={handleSubmit(handleFormSubmit, onError)}
          >
            <div className="text-xl lg:text-2xl py-4 lg:text-current lg:py-4">
              <span>{t("contact-drop-us-a-msg")} </span>
              <a
                target={"_blank"}
                href="mailto: mannydheer@gmail.com"
                className="border-b-2 border-indigo-600"
                rel="noreferrer"
              >
                {t("contact-email-us-here")}
              </a>
              <div className="text-gray-400 text-sm lg:text-lg pt-4">
                {t("contact-project-idea")}
              </div>
            </div>
            <div>
              <label htmlFor="name">{t("contact-name")}*</label>
              <input
                id="name"
                type="text"
                placeholder="John Smith"
                className={`${
                  nameError && "border-2  border-red-600"
                } bg-primary rounded-md p-2 w-full h-12 mt-2`}
                {...register("name", { required: true })}
              />
              {nameError && (
                <div role="alert" className="text-red-600 flex pt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <p>{t("contact-name-required")}</p>
                </div>
              )}
            </div>
            <div>
              <label htmlFor="email">{t("contact-email")}*</label>
              <input
                id="email"
                type="email"
                placeholder="john-smith@test.com"
                className={`mt-2 bg-primary rounded-md p-2 h-12 ${
                  emailError && "border-2 border-red-600"
                } w-full`}
                {...register("email", { required: true })}
              />
              {emailError && (
                <div role="alert" className="text-red-600 flex pt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <p>{t("contact-email-required")}</p>
                </div>
              )}
            </div>
            <div>
              <label htmlFor="phoneNumber">{t("contact-phone-number")}</label>
              <input
                id="phoneNumber"
                type="text"
                aria-placeholder="xxx-xxx-xxxx"
                placeholder="xxx-xxx-xxxx"
                className={`   
                ${preferredContactError && "border-2  border-red-600"} 
                mt-2 bg-primary rounded-md p-2 h-12 w-full`}
                {...register("phoneNumber")}
              />
              {preferredContactError && (
                <div role="alert" className="text-red-600 flex pt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <p>{t("contact-phone-number-required")}</p>
                </div>
              )}
            </div>

            <div>
              <label htmlFor="projectDetails">
                {t("contact-project-details")}*
              </label>
              <textarea
                id="projectDetails"
                placeholder="I am interested in the small business pricing bundle."
                className={`mt-2 bg-primary rounded-md p-2 h-24 ${
                  projectDetailsError && "border-2 border-red-600"
                } w-full`}
                {...register("projectDetails", { required: true })}
              />
              {projectDetailsError && (
                <div role="alert" className="text-red-600 flex pt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <p>{t("contact-project-details-required")}</p>
                </div>
              )}
            </div>
            <div>
              <label htmlFor="preferredContact">
                {t("contact-best-way-to-contact")}
              </label>

              <select
                id="preferredContact"
                className={`mt-2 bg-primary rounded-md p-2 h-12 w-full`}
                {...register("preferredContact", { required: true })}
              >
                <option>{t("contact-please-select")}</option>
                <option value={PreferredContact.Email}>
                  {t("contact-email")}
                </option>
                <option value={PreferredContact.Phone}>
                  {t("contact-phone")}
                </option>
              </select>
            </div>
            <ReCAPTCHA
              ref={recaptchaEl}
              size={"invisible"}
              sitekey={process.env.NEXT_PUBLIC_GOOGLE_SITE_KEY}
            />
            <div>
              <Button disabled={isSendingEmail}>
                {isSendingEmail
                  ? t("contact-sending")
                  : t("contact-sending-message")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
